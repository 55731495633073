<template>
  <h2
    :class="
      cn(
        'col-span-full mb-5 mt-10 text-3xl font-bold text-home-black sm:text-4xl',
        $attrs.class ?? ''
      )
    "
  >
    <slot />
  </h2>
</template>
